import { allLocales, defaultLocalesByLanguage, Locale } from './locales';

export function sanitizeLocale(dirtyLocale: string) {
  const locale = dirtyLocale.toLowerCase().replace('_', '-');
  const [lang] = locale.split('-');

  if (allLocales.includes(locale as Locale)) {
    return locale;
  }

  const countryLocale =
    defaultLocalesByLanguage.find(l => l.startsWith(lang)) ||
    allLocales.find(l => l.startsWith(lang));

  if (!countryLocale) {
    return 'en-us';
  }

  return countryLocale;
}
