/**
 * The locale code that templates in this source code are written in.
 */
export const sourceLocale = 'en-us';

/**
 * All valid project locale codes. Sorted lexicographically.
 */
export const allLocales = [
  'bg-bg',
  'cs-cz',
  'da-dk',
  'de-de',
  'el-gr',
  'en-ae',
  'en-gb',
  'en-hr',
  'en-il',
  'en-ke',
  'en-lk',
  'en-mo',
  'en-mu',
  'en-ng',
  'en-tt',
  'en-us',
  'en-vn',
  'en-xm',
  'es-bo',
  'es-do',
  'es-es',
  'es-la',
  'es-mx',
  'es-na',
  'es-pa',
  'es-py',
  'es-sv',
  'es-uy',
  'et-ee',
  'fi-fi',
  'fil-ph',
  'fr-ca',
  'fr-fr',
  'fr-ma',
  'fr-xm',
  'hi-in',
  'hr-hr',
  'hu-hu',
  'id-id',
  'it-it',
  'in-id',
  'ja-jp',
  'ko-kr',
  'lt-lt',
  'lv-lv',
  'nb-no',
  'nl-nl',
  'ms-my',
  'pl-pl',
  'pt-br',
  'ro-ro',
  'ru-ru',
  'sk-sk',
  'sl-si',
  'sr-rs',
  'sr-sp',
  'sv-se',
  'th-th',
  'tr-tr',
  'uk-ua',
  'vi-vn',
  'zh-cn',
  'zh-hans',
  'zh-hant',
  'zh-mo',
  'zh-tw',
] as const;

export const defaultLocalesByLanguage = [
  'en-us',
  'es-es',
  'fr-fr',
  'sr-rs',
  'zh-cn',
];

export type Locale = (typeof allLocales)[number];
